import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { path } from 'ramda';

import { notificationTypes } from './notificationState';
import { useNotificationContext } from './NotificationContextProvider';
import logger from '../utils/logger';
import { AUTH_EVENTS } from '../constants/auth';
import ERROR_MESSAGES from '../i18n/errors.messages';
import { handleSignOut } from '../utils/authUtils';

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

const createAuthEventListener = (setAuthState, appendNotification) => data => {
  switch (path(['payload', 'event'], data)) {
    case AUTH_EVENTS.signOut:
      return setAuthState({ isAuthenticated: false });
    case AUTH_EVENTS.signIn:
      return getAuthenticatedUser(setAuthState, appendNotification);
    case AUTH_EVENTS.tokenRefresh:
      return getAuthenticatedUser(setAuthState, appendNotification);
    case AUTH_EVENTS.signIn_failure:
      logger.debug('Sign in was unsuccessful.', data);
      appendNotification({
        contentId: ERROR_MESSAGES.signInFailure,
        type: 'warning',
        notificationType: notificationTypes.GLOBAL,
      });
      return setAuthState({ isAuthenticated: false });
    case AUTH_EVENTS.signOut_failure:
      logger.debug('Sign out was unsuccessful.', data);
      return appendNotification({
        contentId: ERROR_MESSAGES.signOutFailure,
        type: 'warning',
        notificationType: notificationTypes.GLOBAL,
      });
    case AUTH_EVENTS.EH_NoCurrentUser:
      handleSignOut();
      return setAuthState({ isAuthenticated: false });
    default:
      logger.debug('Unhandled auth event listener', data);
  }
};

const getCurrentUser = path([
  'signInUserSession',
  'idToken',
  'payload',
  'name',
]);

const getVibeId = path([
  'signInUserSession',
  'idToken',
  'payload',
  'vibe_user_id',
]);

const getToken = path(['signInUserSession', 'idToken', 'jwtToken']);

const getAuthenticatedUser = (setAuthState, appendNotification) => {
  Auth.currentAuthenticatedUser()
    .then(user =>
      setAuthState({
        isAuthenticated: true,
        userData: user,
        currentUser: getCurrentUser(user),
        vibeId: getVibeId(user),
        gandalfToken: getToken(user),
      })
    )
    .catch(err => {
      logger.debug('There was an error getting user data.', err);
      appendNotification({
        contentId: ERROR_MESSAGES.signInFailure,
        type: 'warning',
        notificationType: notificationTypes.GLOBAL,
      });
      return setAuthState({ isAuthenticated: false });
    });
};

const AuthContextProvider = ({ initialState, children }) => {
  const { appendNotification } = useNotificationContext();
  const [authState, setAuthState] = useState({
    isAuthenticated: initialState.isAuthenticated,
    userData: initialState.user,
    currentUser: getCurrentUser(initialState.user),
    vibeId: getVibeId(initialState.user),
    gandalfToken: getToken(initialState.user),
  });

  const authEventListener = useRef(
    createAuthEventListener(setAuthState, appendNotification)
  );

  useEffect(() => {
    const listener = authEventListener.current;
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, []);

  return (
    <AuthContext.Provider value={[authState, setAuthState]}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  initialState: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object,
    gandalfToken: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
