import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { propOr } from 'ramda';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';

import {
  useAuthContext,
  useConfigContext,
  useLocaleContext,
} from '../contexts';
import { ButtonOverride } from '../components/PolarisOverrides';
import CatalogFilterPanel from '../components/Catalog/CatalogFilterPanel';

import metrics, {
  publishButtonClickMetric,
  publishCounterMetric,
} from '../utils/metrics';
import { URL_PATHS } from '../constants/urlPaths';
import HeaderEH from '../components/Header/HeaderEH';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Main from '../components/Main';
import FooterSharedLandingPage from '../components/Footer/FooterSharedLandingPage';
import { AppLayoutOverride } from '../components/PolarisOverrides';
import SEO from '../components/SEO';
import UnauthenticatedCatalog from '../components/UnauthenticatedCatalog/UnauthenticatedCatalog';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import messages from './UnauthenticatedCatalogPage.messages';
import { getQueryParam } from '../utils/queryParamUtils';
import { MODE_QUERY_PARAM, KIOSK_MODE } from '../constants/catalog';
import useGetUnauthenticatedCatalog from '../hooks/useGetUnauthenticatedCatalog';
import { getLocaleOrAvailable } from '../utils/localeUtils';
import {
  createSegmentedOptions,
  getFirstKey,
  getCatalogItems,
  getAllCatalogItems,
  updateSearchQueryString,
  handleFilterPanelToggle,
} from '../utils/catalogUtils';

const UnauthenticatedCatalogPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [locale] = useLocaleContext();
  const location = useLocation();
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const config = useConfigContext();
  const metricsPublisher = useRef(
    metrics.createPublisher('UnauthenticatedCatalogPage')
  );
  const { data, isLoading, isError } = useGetUnauthenticatedCatalog({
    metricsPublisher: metricsPublisher.current,
  });

  const options = createSegmentedOptions(data, locale);

  const enableSharedLxpHomePage =
    propOr([], 'sharedLxpProviders', config).length > 0;
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const appLayoutRef = useRef();
  const [refinerState, setRefinerState] = useState({
    matchesFilterQuery: () => true,
    searchText: '',
    searchResult: null,
  });

  const [isKioskMode] = useState(
    getQueryParam(history.location.search, MODE_QUERY_PARAM) === KIOSK_MODE
  );
  const [catalog, setCatalog] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);

  useEffect(() => {
    publishCounterMetric('UnauthenticatedCatalogPage', {
      additionalMetrics: {
        PathName: window.location.pathname,
      },
    });
  }, []);

  useEffect(() => {
    if (!data) return;

    const currentSelectionId = selectedId || getFirstKey(data);
    const experience = getLocaleOrAvailable(locale, data[currentSelectionId]);
    const catalogItems = getCatalogItems(data, currentSelectionId);

    setSelectedId(currentSelectionId);
    setSelectedExperience(experience);
    setCatalog(catalogItems);

    updateSearchQueryString(history);
    setRefinerState(prevState => ({
      ...prevState,
      searchText: '',
      searchResult: null,
    }));
  }, [data, locale, selectedId, history]);

  const HeaderActions = ({ isKioskMode }) => {
    if (isKioskMode) return null;
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <ButtonOverride
          variant="primary"
          onClick={() => {
            const destinationUrl = enableSharedLxpHomePage
              ? URL_PATHS.sharedLxpHomePage
              : URL_PATHS.homePage;
            publishButtonClickMetric('UnauthenticatedCatalogCTA', {
              destinationUrl,
              currentPath: location.pathname,
              clickLocation: 'UnauthenticatedCatalog',
            });
            history.push(destinationUrl);
          }}
        >
          {formatMessage(messages.cta)}
        </ButtonOverride>
      </SpaceBetween>
    );
  };

  return (
    <>
      <SEO title={formatMessage(messages.title)} />
      <HeaderWrapper>
        <HeaderEH
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
          isStandalone={true}
          disableSignIn={true}
        />
      </HeaderWrapper>
      <AppLayoutOverride
        forwardRef={appLayoutRef}
        maxContentWidth={800}
        content={
          <Main>
            <SpaceBetween direction="vertical" size="l">
              <Header
                variant="h1"
                actions={<HeaderActions isKioskMode={isKioskMode} />}
              >
                {formatMessage(messages.title)}
              </Header>
              <TextContent>{formatMessage(messages.description)}</TextContent>
              <UnauthenticatedCatalog
                isLoading={isLoading}
                isError={isError}
                catalogList={catalog}
                refinerState={refinerState}
                setRefinerState={setRefinerState}
                locale={locale}
                selectedExperience={selectedExperience}
                options={options}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
              />
            </SpaceBetween>
          </Main>
        }
        navigation={
          <CatalogFilterPanel
            catalogList={getAllCatalogItems(data)}
            setRefinerState={setRefinerState}
          />
        }
        navigationHide={false}
        onNavigationChange={handleFilterPanelToggle}
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer__shared"
        stickyNotifications={true}
        ariaLabels={APP_LAYOUT_LABELS}
        toolsHide={true}
      />
      <FooterSharedLandingPage appLayoutRef={appLayoutRef} />
    </>
  );
};

export default UnauthenticatedCatalogPage;
