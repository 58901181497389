import { curry, filter, prop, propEq, pipe, propOr, is } from 'ramda';

import { getLocale } from './localeUtils';

const filterMarkdown = filter(propEq('type', 'MARKDOWN'));

// Provide default empty array to enable chaining
const getLocaleResources = propOr([], 'resources');

export const getMarkdownResources = curry((locale, blueprint) =>
  pipe(getLocale(locale), getLocaleResources, filterMarkdown)(blueprint)
);

export const getBlueprintProp = curry((locale, blueprint, key) =>
  pipe(getLocale(locale), prop(key))(blueprint)
);

/**
 * Blueprint Arn is in the following format
 * arn:<partition>:learningcontent:<region>:<accountId>:blueprintversion/<name>[/<subname>][:<version>]
 *
 * @param {string} arn Arn with optional version
 * @returns {string} Arn without version
 */
export const getArnWithoutVersion = arn => {
  if (!is(String, arn)) return '';
  const split = arn.split('/');
  const lastIndex = split.length - 1;
  split[lastIndex] = split[lastIndex].split(':')[0];
  return split.join('/');
};

/**
 * Blueprint Arn is in the following format
 * arn:<partition>:learningcontent:<region>:<accountId>:blueprintversion/<name>[/<subname>][:<version>]
 *
 * @param {string} arn Arn with optional version
 * @returns {string} Blueprint name
 */
export const getBlueprintName = arn => {
  const pattern = /blueprintversion\/([^:]+)/;
  const match = arn?.match(pattern);
  return match ? match[1] : '';
};

export default {
  getMarkdownResources,
  getBlueprintProp,
  getArnWithoutVersion,
};
