import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { useAuthContext, useEventContext, useLocaleContext } from '../contexts';
import Catalog from '../components/Catalog/Catalog';
import CatalogFilterPanel from '../components/Catalog/CatalogFilterPanel';
import FEATURES from '../constants/features';
import { publishCounterMetric } from '../utils/metrics';
import HeaderEH from '../components/Header/HeaderEH';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Main from '../components/Main';
import { AppLayoutOverride } from '../components/PolarisOverrides';
import SEO from '../components/SEO';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import {
  getCatalogItems,
  handleFilterPanelToggle,
} from '../utils/catalogUtils';
import metrics from '../utils/metrics';
import useGetUnauthenticatedCatalog from '../hooks/useGetUnauthenticatedCatalog';
import FooterSharedLandingPage from '../components/Footer/FooterSharedLandingPage';
import {
  JAMS_LXP_KEY,
  SIMULEARN_LXP_KEY,
  LXP_CATALOG_MAP,
} from '../constants/lxpKeys';
import messages from './LXPCatalogPage.messages';

const LXPCatalogPage = ({ lxpKey }) => {
  const { formatMessage } = useIntl();
  const [{ isAuthenticated, currentUser }] = useAuthContext();
  const { event } = useEventContext();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const appLayoutRef = useRef();
  const metricsPublisher = useRef(metrics.createPublisher('LXPCatalogPage'));
  const [locale] = useLocaleContext();
  const { data, isLoading, isError } = useGetUnauthenticatedCatalog({
    metricsPublisher: metricsPublisher.current,
  });
  const [catalog, setCatalog] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [refinerState, setRefinerState] = useState({
    matchesFilterQuery: () => true,
    searchText: '',
    searchResult: null,
  });

  useEffect(() => {
    if (!data) return;
    const lxpCatalogKey = LXP_CATALOG_MAP[lxpKey];
    if (!lxpCatalogKey) return; // only display lxp content from lxps in map
    const catalogItems = getCatalogItems(data, lxpCatalogKey);
    setCatalog(catalogItems);
    setRefinerState(prevState => ({
      ...prevState,
      searchText: '',
      searchResult: null,
    }));

    switch (lxpKey) {
      case JAMS_LXP_KEY:
        setPageTitle(formatMessage(messages.jamsTitle));
        break;
      case SIMULEARN_LXP_KEY:
        setPageTitle(formatMessage(messages.simulearnTitle));
        break;
      default:
        break;
    }
  }, [data, lxpKey, formatMessage]);

  useEffect(() => {
    publishCounterMetric(`LXPCatalogPage-${lxpKey}`);
  }, [lxpKey]);

  return (
    <>
      <SEO title={pageTitle} />
      <HeaderWrapper>
        <HeaderEH
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
          isStandalone={true}
        />
      </HeaderWrapper>
      <AppLayoutOverride
        forwardRef={appLayoutRef}
        maxContentWidth={1000}
        content={
          <Main>
            <Catalog
              isLoading={isLoading}
              isError={isError}
              catalogList={catalog}
              refinerState={refinerState}
              setRefinerState={setRefinerState}
              localePref={locale}
              headerText={pageTitle}
            />
          </Main>
        }
        navigation={
          event.hasFeature(FEATURES.catalogFiltering) && (
            <CatalogFilterPanel
              catalogList={catalog}
              setRefinerState={setRefinerState}
            />
          )
        }
        navigationHide={!event.hasFeature(FEATURES.catalogFiltering)}
        onNavigationChange={handleFilterPanelToggle}
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer__shared"
        stickyNotifications={true}
        ariaLabels={APP_LAYOUT_LABELS}
        toolsHide={true}
      />
      <FooterSharedLandingPage appLayoutRef={appLayoutRef} />
    </>
  );
};

LXPCatalogPage.propTypes = {
  lxpKey: PropTypes.string.isRequired,
};

export default LXPCatalogPage;
