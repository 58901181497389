import { keys, head, length, path, pipe, propSatisfies } from 'ramda';

import { EHBlueprint } from '../models/index.js';
import { getLocaleOrAvailable } from './localeUtils.js';
import { updateQueryParam } from './queryParamUtils.js';
import { SEARCH_QUERY_PARAM } from '../constants/catalog.js';
import { publishButtonClickMetric } from './metrics.js';
import { catalogMetricsNames } from '../constants/catalog.js';
import { getArnWithoutVersion } from './blueprintUtils';

export const getFirstKey = pipe(keys, head);

export const createSegmentedOptions = (config, locale) => {
  const options = [];
  if (!config || typeof config !== 'object') return options;

  Object.keys(config).forEach(key => {
    const item = getLocaleOrAvailable(locale, config[key]);

    if (item && item.title) {
      const optionConfig = { text: item.title, id: key };
      options.push(optionConfig);
    }
  });

  return options;
};

/**
 * Retrieves catalog items by filtering and mapping blueprints from the selected config.
 *
 * @param {Object} config - The configuration object containing blueprint data for each catalog item.
 * @param {string} selectedId - The ID of the selected catalog item.
 * @returns {EHBlueprint[]} An array of EHBlueprint instances that have valid locales.
 */
export const getCatalogItems = (config, selectedId) => {
  if (!config || !selectedId || !config[selectedId]) return [];
  const hasValidLocales = propSatisfies(length, 'locales');
  const { blueprints } = config[selectedId];
  return blueprints.map(data => new EHBlueprint(data)).filter(hasValidLocales);
};

/**
 * Retrieves all catalog items across all sections by collecting and mapping blueprints from the entire config.
 *
 * @param {Object} config - The configuration object containing blueprint data for all catalog sections.
 * @returns {EHBlueprint[]} An array of EHBlueprint instances that have valid locales, combined from all sections.
 */
export const getAllCatalogItems = config => {
  if (!config) return [];
  const hasValidLocales = propSatisfies(length, 'locales');

  return Object.values(config)
    .flatMap(section => section.blueprints || [])
    .map(data => new EHBlueprint(data))
    .filter(hasValidLocales);
};

/**
 * Updates the search query string in the URL by removing or setting the value of the search query parameter.
 *
 * @param {Object} history - The history object from React Router to manage session history.
 */
export const updateSearchQueryString = history => {
  const modQueryString = updateQueryParam(
    history.location.search,
    SEARCH_QUERY_PARAM,
    ''
  );
  history.replace({
    pathname: history.location.pathname,
    search: modQueryString ? `?${modQueryString}` : '',
  });
};

/**
 * Handles metrics publishing for filter panel toggle events
 *
 * @param {Object} event - The toggle event object
 * @param {Object} event.detail - Event details containing panel state
 * @param {boolean} event.detail.open - Whether panel is being opened or closed
 */
export const handleFilterPanelToggle = event => {
  const panelOpen = path(['detail', 'open'], event);
  publishButtonClickMetric(
    panelOpen
      ? catalogMetricsNames.filterPanelOpen
      : catalogMetricsNames.filterPanelClose
  );
};

/**
 * Adds labId to URL if blueprint arn matches current ongoing lab.
 * Assumes there can only be one ongoing lab at a time.
 *
 * @param {String} blueprintArn Blueprint arn for the catalog item
 * @param {String} locale Locale of the catalog item
 * @param {String} ongoingBlueprintArn Blueprint arn for ongoing lab
 * @param {String} ongoingLabId Lab id for ongoing lab
 * @returns {String} URI for catalog item
 */
export const getUriForCatalogItem = (
  blueprintArn,
  locale,
  ongoingBlueprintArn,
  ongoingLabId
) => {
  const defaultString = `/lab/${encodeURIComponent(blueprintArn)}/${locale}`;

  // no ongoing lab
  if (!ongoingBlueprintArn) return defaultString;

  // both arns include the same versions
  if (blueprintArn === ongoingBlueprintArn)
    return `/lab/${encodeURIComponent(blueprintArn)}/${locale}/${ongoingLabId}`;

  // blueprints match but not version (ex. spotlightArn does not have version id)
  // return ongoing lab with specified version id
  if (
    getArnWithoutVersion(blueprintArn) ===
    getArnWithoutVersion(ongoingBlueprintArn)
  )
    return `/lab/${encodeURIComponent(
      ongoingBlueprintArn
    )}/${locale}/${ongoingLabId}`;

  // default case
  return defaultString;
};
