import React, { useRef, useEffect } from 'react';
import { AppLayout, Box } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import messages from './SharedLxpHomePage.messages';
import SEO from '../components/SEO';
import { HeaderWrapper, HeaderEH } from '../components/Header';
import FooterSharedLandingPage from '../components/Footer/FooterSharedLandingPage';
import AppNotifications from '../components/AppNotifications';
import Main from '../components/Main';
import { useAuthContext } from '../contexts';
import metrics from '../utils/metrics';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import { IMPRESSIONS } from '../constants/metrics';
import { SkillBuilderBanner } from '../components/SkillBuilder';
import { CustomCallouts } from '../components/CustomCallouts';
import { SLOTS } from '../components/CustomCallouts/constants';

import './SharedLxpHomePage.scss';

const SharedLxpHomePage = () => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const [{ isAuthenticated, currentUser }] = useAuthContext();

  const metricsPublisher = useRef(metrics.createPublisher('SharedLxpHomePage'));
  useEffect(() => metricsPublisher.current.publishCounter(IMPRESSIONS, 1), []);

  return (
    <>
      <SEO title={formatMessage(messages.title)} />
      <HeaderWrapper>
        <HeaderEH
          isStandalone={true}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
        />
      </HeaderWrapper>
      <AppLayout
        ref={appLayoutRef}
        content={
          <Main>
            <h1 className="awsui-util-hide">{formatMessage(messages.title)}</h1>
            <CustomCallouts slot={SLOTS.lxpHomeHero} />
            <div className="SharedLxpHomePage__content">
              <Box
                variant="h1"
                tagOverride="h2"
                margin={{
                  bottom: 'm',
                }}
                textAlign="center"
              >
                {formatMessage(messages.introHeader)}
              </Box>
              <div className="SharedLxpHomeTop__layout">
                <CustomCallouts slot={SLOTS.lxpHomeTop} />
              </div>
              <div className="SharedLxpHomeBottom__spacing">
                <CustomCallouts slot={SLOTS.lxpHomeBottom} />
              </div>
              <SkillBuilderBanner />
            </div>
          </Main>
        }
        variant="navless"
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer__shared"
        notifications={<AppNotifications />}
        stickyNotifications
        navigationHide
        toolsHide
        disableContentPaddings
        maxContentWidth={Number.MAX_VALUE}
        ariaLabels={APP_LAYOUT_LABELS}
      />
      <FooterSharedLandingPage appLayoutRef={appLayoutRef} />
    </>
  );
};

SharedLxpHomePage.propTypes = {};

export default SharedLxpHomePage;
