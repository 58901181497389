import React from 'react';
import {
  EventHorizonPromoBanner,
  useEHChatBotVisibility,
} from '@amzn/aws-tc-ai-coaching-frontend';

import { showChatBotPromoBanner } from './utils';
import { useEventContext, useLocaleContext } from '../../contexts';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const ChatBotPromoBanner = ({ blueprintName, isLabUnderMaintenance }) => {
  const { event } = useEventContext();
  const [userLocale] = useLocaleContext();
  const { shouldRenderBanner } = useEHChatBotVisibility();

  if (
    !showChatBotPromoBanner({
      eventContext: event,
      blueprintName,
    }) ||
    !shouldRenderBanner(userLocale) ||
    isLabUnderMaintenance
  ) {
    return null;
  }

  return (
    <span data-testid={dataTestId['ai-chatbot-promobanner-eh']}>
      <EventHorizonPromoBanner />
    </span>
  );
};

export default ChatBotPromoBanner;
