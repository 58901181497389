import React from 'react';
import PropTypes from 'prop-types';

import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control';

import Catalog from '../Catalog/Catalog';
import LoadingSkeleton from '../LoadingSkeleton';

const UnauthenticatedCatalog = ({
  isLoading,
  isError,
  catalogList,
  refinerState,
  setRefinerState,
  locale,
  options,
  selectedId,
  setSelectedId,
  selectedExperience,
}) => {
  return (
    <SpaceBetween direction="vertical" size="l">
      {options && selectedId ? (
        <SegmentedControl
          selectedId={selectedId}
          onChange={({ detail }) => setSelectedId(detail.selectedId)}
          label="Catalog options"
          options={options}
        />
      ) : (
        <LoadingSkeleton width={50} height={32} />
      )}

      {selectedExperience ? (
        <Box>{selectedExperience.description}</Box>
      ) : (
        <SpaceBetween direction="vertical" size="xs">
          <LoadingSkeleton width={60} height={24} />
          <LoadingSkeleton width={100} height={24} />
        </SpaceBetween>
      )}

      <Catalog
        isLoading={isLoading}
        isError={isError}
        catalogList={catalogList}
        refinerState={refinerState}
        setRefinerState={setRefinerState}
        localePref={locale}
        disableLinks={true}
      />
    </SpaceBetween>
  );
};

UnauthenticatedCatalog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  catalogList: PropTypes.array.isRequired,
  refinerState: PropTypes.shape({
    matchesFilterQuery: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    searchResult: PropTypes.any.isRequired,
  }).isRequired,
  setRefinerState: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  selectedId: PropTypes.string,
  setSelectedId: PropTypes.func.isRequired,
  selectedExperience: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }),
};

export default UnauthenticatedCatalog;
