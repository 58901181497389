import React, { useState, useRef, useCallback, useEffect } from 'react';
import { AppLayout, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { prop, path, equals, propOr, pathOr, propEq, find } from 'ramda';
import { v4 as uuid } from 'uuid';
import parse from 'url-parse';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ERROR_TYPES from '../constants/errorTypes';
import { publishFatal } from '../utils/metrics';

import ErrorPage from './ErrorPage';
import SEO from '../components/SEO';
import LabSidebar from '../components/LabUI/LabSidebar';
import LabNavigation from '../components/LabUI/LabNavigation';
import LabSecrets from '../components/LabUI/LabSecrets';
import { HeaderWrapper, HeaderEH } from '../components/Header';
import { Footer } from '../components/Footer';
import AppNotifications from '../components/AppNotifications';
import { OngoingLabBanner } from '../components/OngoingLab';
import LabActions from '../components/LabUI/LabHeader/LabActions';
import LabHeader from '../components/LabUI/LabHeader/LabHeader';
import usePollGetLab from '../hooks/usePollGetLab';
import useGetLabSession from '../hooks/useGetLabSession';
import LabMarkdown from '../components/LabUI/LabMarkdown';
import LabCreditBanner from '../components/LabUI/LabCreditBanner';
import { LabStateNotification } from '../components/LabUI/LabStateNotification';
import ERROR_MESSAGES from '../i18n/errors.messages';
import Main from '../components/Main';
import { HelpPanel } from '../components/HelpPanel';
import { ChatBot, ChatBotPromoBanner } from '../components/ChatBot';
import { CustomCallouts } from '../components/CustomCallouts';
import {
  isOngoing,
  useLabContext,
  notificationTypes,
  useLocaleContext,
  useNotificationContext,
  useAuthContext,
  useHelpPanelContext,
  useEventContext,
  useTourContext,
} from '../contexts';
import useAppLayoutLabels from '../hooks/useAppLayoutLabels';
import {
  getBlueprintProp,
  getArnWithoutVersion,
  getBlueprintName,
} from '../utils/blueprintUtils';
import metrics from '../utils/metrics';
import { getLocaleOrAvailable, getLanguageCode } from '../utils/localeUtils';
import configService from '../utils/configService';
import { delay } from '../utils/promiseUtils';
import { IMPRESSIONS } from '../constants/metrics';
import { LOCALES } from '../constants/locale';
import messages from './LabPage.messages';
import {
  getLabStateByField,
  LAB_PROVISIONING_ATTEMPTS,
  actions,
  loadingStatuses,
  labStatuses,
} from '../contexts/labState';
import {
  BLUEPRINT_AVAILABILITY,
  BLUEPRINT_STATUS,
} from '../constants/blueprint';
import {
  CONSOLE_SIGNOUT_URL,
  SANDBOX_CONSOLE_WINDOW_NAME,
} from '../constants/lab';
import LabMarkdownLoading from '../components/LabUI/LabMarkdownLoading';
import useGetBlueprint from '../hooks/useGetBlueprint';
import useGetLabCreditsRemaining from '../hooks/useGetLabCreditsRemaining';
import LabEvaluationContainer from '../components/LabUI/LabEvaluation/LabEvaluationContainer';
import useSplitPanelProps from '../hooks/useSplitPanelProps';
import FEATURES from '../constants/features';
import { FeedbackSurveyBox } from '../components/Feedback';
import { SkillBuilderBanner } from '../components/SkillBuilder';
import { SLOTS } from '../components/CustomCallouts/constants';
import {
  getUserHourCycle,
  getUserTimeZone,
  parseDateTime,
} from '../utils/dateTimeUtils';
import './LabPage.scss';

/**
 * @param {*} creditState
 * @returns true only if hasEnoughCredits strictly equals false, false otherwise
 */
const hasNoCredits = creditState =>
  equals(prop('hasEnoughCredits', creditState), false);

const openSignoutConsole = async () => {
  const config = await configService.get();
  if (propOr(0, 'forcedSignoutDelayMs', config) > 0) {
    window.open(CONSOLE_SIGNOUT_URL, SANDBOX_CONSOLE_WINDOW_NAME);
    await delay(config.forcedSignoutDelayMs);
  }
};

const appendLocaleToConsoleUrl = (consoleUrl, locale) => {
  const parsedUrl = parse(consoleUrl, true);
  const query = prop('query', parsedUrl);
  const destination = parse(path(['query', 'Destination'], parsedUrl), true);
  const destinationQuery = prop('query', destination);
  destination.set('query', { ...destinationQuery, locale: locale });
  parsedUrl.set('query', { ...query, Destination: destination.href });
  return parsedUrl.href;
};

const openConsole = async (consoleUrl, locale) => {
  const newConsoleUrl = locale
    ? appendLocaleToConsoleUrl(consoleUrl, locale)
    : consoleUrl;
  window.open(newConsoleUrl, SANDBOX_CONSOLE_WINDOW_NAME);
};

export const getExpirationData = (expiresOn, locale) => {
  if (!expiresOn || !locale) {
    return undefined;
  }
  const timeZone = getUserTimeZone();
  const hourCycle = getUserHourCycle();

  const dateOptions = { month: 'short', day: '2-digit', timeZone: timeZone };
  const timeOptions = {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: timeZone,
    hourCycle: hourCycle,
    ...(timeZone === 'UTC' ? { timeZoneName: 'short' } : {}),
  };

  const expiryDate = parseDateTime(expiresOn, dateOptions, locale);
  const expiryTime = parseDateTime(expiresOn, timeOptions, locale);
  return { expiryDate, expiryTime };
};

const LabPage = ({ match, rootPath, isStandalone }) => {
  const { formatMessage } = useIntl();
  const appLayoutRef = useRef();
  const [userLocale] = useLocaleContext();
  const { event } = useEventContext();
  const { setTourEnabled } = useTourContext();
  const history = useHistory();
  const APP_LAYOUT_LABELS = useAppLayoutLabels();
  const [expirationData, setExpirationData] = useState(null);

  const {
    encodedBlueprintArn,
    blueprintLocale,
    labId: ongoingLabId,
  } = match.params;
  const isOngoingLab = Boolean(ongoingLabId);
  const concurrentLabsLimit = event.getConcurrentLabsLimit();

  const labPagePublisher = useRef(metrics.createPublisher('LabPage'));
  const pageLoadTimeMetric = useRef(
    metrics.createTimerStopWatch('PageLoadTime').withMonitor()
  );

  const {
    data: blueprint,
    isLoading: isLoadingBlueprint,
    error: getBlueprintError,
  } = useGetBlueprint({
    blueprintArn: decodeURIComponent(encodedBlueprintArn),
    metricsPublisher: labPagePublisher.current,
  });

  useEffect(() => {
    if (!isLoadingBlueprint && !getBlueprintError) {
      setTourEnabled(true);
    }
  }, [isLoadingBlueprint, getBlueprintError, setTourEnabled]);

  const { data: creditState, error: creditStateError } =
    useGetLabCreditsRemaining();

  const [toc, setToc] = useState();
  const {
    labState: labStateFromContext,
    setHasQueriedOngoing,
    hasQueriedOngoing,
    startLab,
    isStartingLab,
    stopLab,
    isStoppingLab,
    dispatch,
    getOngoingLabsError,
  } = useLabContext();

  const labState = getLabStateByField(
    labStateFromContext.labs,
    decodeURIComponent(encodedBlueprintArn)
  );

  const { refetch: getLab } = usePollGetLab({
    labId: labState.labId,
    metricsPublisher: labPagePublisher.current,
    onSuccess: data => {
      dispatch({
        type: actions.GET_LAB_RESPONSE,
        status: data.status,
        secrets: data.secrets,
        createdOn: data.createdOn,
        expiresOn: data.expiresOn,
        estimatedReadyTime: data.estimatedReadyTime,
        labId: labState.labId,
      });
      setHasQueriedOngoing(true);
    },
    onError: error => {
      const errors = propOr([], 'errors', error);
      for (const error of errors) {
        switch (prop('errorType', error)) {
          case ERROR_TYPES.BadRequest:
          case ERROR_TYPES.NotFound:
            appendNotification({
              contentId: ERROR_MESSAGES.getLab404,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            dispatch({
              type: actions.END_LAB_RESPONSE,
              labId: labState.labId,
            });
            return;
          case ERROR_TYPES.LabEnded:
            dispatch({
              type: actions.END_LAB_RESPONSE,
              labId: labState.labId,
            });
            return;
          case ERROR_TYPES.LabExpired:
            appendNotification({
              contentId: ERROR_MESSAGES.sessionEnded,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            dispatch({
              type: actions.END_LAB_RESPONSE,
              labId: labState.labId,
            });
            return;
          case ERROR_TYPES.LabCancelled:
            appendNotification({
              contentId: ERROR_MESSAGES.sessionCancelled,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            dispatch({
              type: actions.END_LAB_RESPONSE,
              labId: labState.labId,
            });
            return;
          case ERROR_TYPES.ProvisioningError:
            if (labState.attempt < LAB_PROVISIONING_ATTEMPTS) {
              startLab({
                attempt: labState.attempt,
                acceptTerms: labState.acceptTerms,
                blueprintArn: labState.blueprintArn,
                blueprintLocale: labState.blueprintLocale,
                clientRequestToken: uuid(), // Generate a new UUID so that idempotent back-ends don't just reflect back the previously failed lab.
              });
              return;
            }
            appendNotification({
              contentId: ERROR_MESSAGES.startLabDefault,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            publishFatal('GetLabPoll', error, { labId: labState.labId });
            dispatch({
              type: actions.END_LAB_RESPONSE,
              labId: labState.labId,
            });
            return;
        }
      }
      // If no match is found, show default error notification
      appendNotification({
        contentId: ERROR_MESSAGES.getLabDefault,
        type: 'warning',
        notificationType: notificationTypes.LAB,
      });
      dispatch({ type: actions.GET_LAB_ERROR, labId: labState.labId });
      publishFatal('GetLabPoll', error, { labId: labState.labId });
      setHasQueriedOngoing(true);
    },
  });

  const { refetch: getLabSession, isLoading: isGettingLabSession } =
    useGetLabSession({
      labId: labState.labId,
      labStatus: labState.status,
      metricsPublisher: labPagePublisher.current,
      onSuccess: data => {
        dispatch({
          type: actions.GET_SESSION_RESPONSE,
          consoleUrl: data.consoleUrl,
          endsOn: data.endsOn,
          labId: labState.labId,
        });
      },
      onError: error => {
        const errors = propOr([], 'errors', error);
        for (const error of errors) {
          switch (prop('errorType', error)) {
            case ERROR_TYPES.BadRequest:
            case ERROR_TYPES.NotFound:
              appendNotification({
                contentId: ERROR_MESSAGES.getLab404,
                type: 'warning',
                notificationType: notificationTypes.LAB,
              });
              dispatch({
                type: actions.END_LAB_RESPONSE,
                labId: labState.labId,
              });
              return;
            case ERROR_TYPES.Conflict:
              // Determine conflict reason through getLab call.
              // Possible lab states are ENDED/EXPIRED/CLOSED.
              if (isOngoing(labState.status)) getLab();
              return;
            case ERROR_TYPES.SessionAlreadyExistsError:
              appendNotification({
                contentId: ERROR_MESSAGES.startLab409,
                type: 'warning',
                notificationType: notificationTypes.LAB,
              });
              dispatch({
                type: actions.GET_SESSION_ERROR,
                labId: labState.labId,
              });
              return;
          }
        }
        publishFatal('GetLabSession', error, { labId: labState.labId });
        // If no match is found, show default error notification
        appendNotification({
          contentId: ERROR_MESSAGES.getLabDefault,
          type: 'warning',
          notificationType: notificationTypes.LAB,
        });
        dispatch({ type: actions.GET_SESSION_ERROR, labId: labState.labId });
      },
    });

  const {
    appendNotification,
    searchAndRemoveNotification,
    clearLabNotifications,
  } = useNotificationContext();
  const { setSplitPanelOpen, appLayoutSplitPanelProps } = useSplitPanelProps();
  const [{ isAuthenticated, currentUser, gandalfToken }] = useAuthContext();
  const { showHelpPanel, handlePanelClose } = useHelpPanelContext();

  const getBlueprintInfo = getBlueprintProp(blueprintLocale, blueprint);
  const langAttr = getLanguageCode(blueprintLocale);

  useEffect(() => {
    /**
     * Labs deeplinked with specified locale or no locale
     * will now redirect using userLocale from LocaleContextProvider.
     * This means the lab page will:
     *    1. Get lab in search param locale (e.g.: ?locale=xx-XX)
     *    2. Get lab in user selected locale (stored in local storage)
     *    3. Get lab in browser language
     *    4. Get lab in default event locale (pulled via EMS data)
     *    5. Get lab in whatever locale is available
     *
     * Related SIMs:
     *    - https://sim.amazon.com/issues/EventHorizon-1372
     *    - https://sim.amazon.com/issues/EventHorizon-1340
     */

    if (!blueprint.arn) return;

    const { locale } = getLocaleOrAvailable(userLocale, blueprint);
    if (
      isOngoing(labState.status) &&
      getArnWithoutVersion(labState.blueprintArn) ===
        getArnWithoutVersion(blueprint.arn)
    ) {
      history.replace(
        `${rootPath}/${encodeURIComponent(labState.blueprintArn)}/${locale}/${
          labState.labId
        }`
      );
    } else {
      history.replace(
        `${rootPath}/${encodeURIComponent(blueprint.arn)}/${locale}`
      );
    }
  }, [
    blueprint,
    userLocale,
    labState.status,
    labState.blueprintArn,
    labState.labId,
    labState.expiresOn,
    history,
    rootPath,
  ]);

  useEffect(() => {
    // Publish metric for invalid labId entered in the URL
    if (ongoingLabId && ongoingLabId !== labState.labId) {
      labPagePublisher.current.publishCounter(`InvalidLabId${IMPRESSIONS}`, 1);
      labPagePublisher.current.publishString('InvalidLabId', {
        blueprintArn: decodeURIComponent(encodedBlueprintArn),
        labId: ongoingLabId,
      });
    }
  }, [labState.labId, ongoingLabId, encodedBlueprintArn]);

  useEffect(() => {
    searchAndRemoveNotification({
      searchKey: 'contentId',
      searchValue: messages.localeNotAvailable,
    });
    if (
      !getBlueprintError &&
      !isLoadingBlueprint &&
      blueprintLocale !== userLocale
    ) {
      appendNotification({
        contentId: messages.localeNotAvailable,
        messageValues: {
          language: LOCALES[userLocale].inline,
        },
        type: 'info',
        notificationType: notificationTypes.LAB,
      });
    }
  }, [
    blueprintLocale,
    userLocale,
    getBlueprintError,
    appendNotification,
    searchAndRemoveNotification,
    isLoadingBlueprint,
  ]);

  useEffect(() => {
    if (!isLoadingBlueprint) {
      labPagePublisher.current.publishCounter(IMPRESSIONS, 1);
      labPagePublisher.current.publishMetric(pageLoadTimeMetric.current);

      if (isStandalone)
        labPagePublisher.current.publishCounter(`Standalone${IMPRESSIONS}`, 1);

      setHasQueriedOngoing(true);
    }
  }, [isLoadingBlueprint, isStandalone, setHasQueriedOngoing]);

  useEffect(() => {
    // Clear all lab notifications when user navigates to different page
    return () => clearLabNotifications();
  }, [clearLabNotifications, encodedBlueprintArn]);

  const [isLabUnderMaintenance, setIsLabUnderMaintenance] = useState(false);

  useEffect(() => {
    if (!blueprint.arn) return;
    // Is the Blueprint in an OK state? See Bunsen ref, https://tiny.amazon.com/clodyzxm
    const isInvalidStatus = blueprint.status !== BLUEPRINT_STATUS.VALIDATED;
    const isInactive = blueprint.availability !== BLUEPRINT_AVAILABILITY.ACTIVE;
    if (!isInvalidStatus && !isInactive) return;

    setIsLabUnderMaintenance(true);
    appendNotification({
      contentId: messages.labUnderMaintenance,
      notificationType: notificationTypes.LAB,
      type: 'info',
      dismissible: false,
    });
    const metricPrefix = isInvalidStatus
      ? 'BlueprintInvalid'
      : 'BlueprintInactive';
    labPagePublisher.current.publishCounter(`${metricPrefix}${IMPRESSIONS}`, 1);
    labPagePublisher.current.publishString(`${metricPrefix}Arn`, blueprint.arn);
  }, [blueprint, appendNotification]);

  const handleStartLab = useCallback(
    acceptTerms => {
      startLab({
        attempt: labState.attempt,
        blueprintArn: blueprint.arn,
        blueprintLocale,
        clientRequestToken: uuid(),
        acceptTerms,
      });
    },
    [startLab, blueprint.arn, blueprintLocale, labState.attempt]
  );

  const handleStopLab = useCallback(() => {
    stopLab({
      labId: labState.labId,
      blueprintArn: labState.blueprintArn,
      status: labState.status,
    });
  }, [stopLab, labState.labId, labState.blueprintArn, labState.status]);

  const handleOpenConsole = useCallback(async () => {
    if (labState.consoleUrlTtl && Date.now() < labState.consoleUrlTtl) {
      await openSignoutConsole();
      openConsole(labState.consoleUrl, userLocale);
      return;
    }
    // If lab consoleUrl is expired, refetch getLabSession query
    // Due to the popup block behavior, we need to call first and await after we open the console to minimize user wait time
    // https://attacomsian.com/blog/javascript-open-url-in-new-tab#asynchronous-http-request
    const getLabSessionPromise = getLabSession();
    await openSignoutConsole();
    const { data } = await getLabSessionPromise;
    const consoleUrl = path(['consoleUrl'], data);
    openConsole(consoleUrl, userLocale);
  }, [labState.consoleUrl, labState.consoleUrlTtl, userLocale, getLabSession]);

  const labEvalautionIsEnabled =
    event.hasFeature(FEATURES.labEvaluation) && blueprint.hasLabEvaluation();
  const hasSubmissionDisabled = blueprint.hasSubmissionDisabled();

  const hasPreviouslySuccessfulResult = labState.evaluations
    ? Boolean(
        labState.evaluations.find(
          item => item?.evaluationStatus === loadingStatuses.DONE
        )
      )
    : false;

  const hasSubmittedSuccessfulResult = labState.evaluations
    ? Boolean(
        labState.evaluations.find(
          item => item?.submissionStatus === loadingStatuses.DONE
        )
      )
    : false;

  const currentEvalSubmissionStatus = pathOr(
    undefined, // default value
    ['evaluations', 0, 'submissionStatus'], // path to submissionStatus value
    labState
  );

  useEffect(() => {
    // Open the split panel by default when a blueprint has Lab Evaluation enabled
    if (labEvalautionIsEnabled) setSplitPanelOpen(true);
    else setSplitPanelOpen(false);
  }, [labEvalautionIsEnabled, setSplitPanelOpen]);

  useEffect(() => {
    if (labState.expiresOn) {
      const newExpirationData = getExpirationData(
        labState.expiresOn,
        userLocale
      );
      setExpirationData(newExpirationData);
    }
  }, [labState.expiresOn, userLocale]);

  if (getOngoingLabsError) {
    const errors = propOr([], 'errors', getOngoingLabsError);
    if (find(propEq('errorType', ERROR_TYPES.NoActiveLab), errors)) {
      return (
        <ErrorPage
          errors={prop('errors', getOngoingLabsError)}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
          isStandalone={isStandalone}
        />
      );
    } else {
      // noop - ignore
      // Adding a comment to avoid future confusion. No-action was the previous
      // experience before adding the NoActiveLab check. It's possible we want
      // to manage this error specifically in some way later, however keeping
      // the previous experience for now.
    }
  }

  if (getBlueprintError) {
    return (
      <ErrorPage
        errors={prop('errors', getBlueprintError)}
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
        isStandalone={isStandalone}
      />
    );
  }

  return (
    <>
      <SEO
        title={
          isLoadingBlueprint
            ? 'Loading...'
            : getBlueprintInfo('title') || formatMessage(messages.seoError)
        }
      />
      <HeaderWrapper>
        <HeaderEH
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
          isStandalone={isStandalone}
        />

        <LabHeader title={getBlueprintInfo('title')} lang={langAttr}>
          <LabActions
            isAuthenticated={isAuthenticated}
            status={labState.status}
            onStartLab={handleStartLab}
            onStopLab={handleStopLab}
            onOpenConsole={handleOpenConsole}
            isOngoingLab={isOngoingLab}
            hasQueriedOngoing={hasQueriedOngoing}
            hasNoCredits={hasNoCredits(creditState)}
            isLabUnderMaintenance={isLabUnderMaintenance}
            isGettingLabSession={isGettingLabSession}
            isStartingLab={isStartingLab}
            isStoppingLab={isStoppingLab}
            isUnderConcurrentLabsLimit={
              labStateFromContext.labs.length < concurrentLabsLimit
            }
            hasLabEvaluation={labEvalautionIsEnabled}
            hasCheckedLabEvaluationScore={hasPreviouslySuccessfulResult}
            hasSubmittedLabEvaluationScore={hasSubmittedSuccessfulResult}
            hasSubmissionDisabled={hasSubmissionDisabled}
            isLabEvaluationPanelShown={appLayoutSplitPanelProps.splitPanelOpen}
            onOpenLabEvaluationPanel={() => setSplitPanelOpen(true)}
            currentEvalSubmissionStatus={currentEvalSubmissionStatus}
          />
        </LabHeader>
      </HeaderWrapper>

      <AppLayout
        ref={appLayoutRef}
        disableBodyScroll={true} // Fixes scroll issue in SiteTour
        headerSelector=".HeaderWrapper"
        footerSelector=".Footer"
        stickyNotifications={true}
        navigationHide={false}
        toolsHide={!showHelpPanel}
        toolsOpen={showHelpPanel}
        onToolsChange={handlePanelClose}
        tools={<HelpPanel />}
        splitPanel={
          labEvalautionIsEnabled && (
            <LabEvaluationContainer
              isOngoingLab={isOngoingLab}
              isStoppingLab={isStoppingLab}
              blueprint={blueprint}
            />
          )
        }
        {...appLayoutSplitPanelProps}
        navigation={
          <LabSidebar
            blueprint={blueprint}
            expirationData={expirationData}
            isOngoingLab={isOngoingLab}
          >
            {isOngoingLab && <LabSecrets secrets={labState.secrets} />}
            <LabNavigation content={toc} lang={langAttr} />
          </LabSidebar>
        }
        /*
            navigationWidth default value=280, https://cloudscape.aws.dev/components/app-layout/?tabId=api
            increasing the width slightly to make the expiration time under Lab information appear on the same line.
            this make it look aesthetically better without altering the layout of the page too much.
            Note: still for some languages with longer translation strings, the expiration info breaks into second line
         */
        navigationWidth={300}
        notifications={
          <>
            <LabStateNotification
              attempt={labState.attempt}
              createdOn={labState.createdOn}
              expirationData={expirationData}
              estimatedReadyTime={labState.estimatedReadyTime}
              isGettingLabSession={isGettingLabSession}
              isOngoingLab={isOngoingLab}
              status={labState.status}
            />
            <AppNotifications />
          </>
        }
        ariaLabels={APP_LAYOUT_LABELS}
        content={
          <Main className="LabPage__wrapper">
            <SpaceBetween direction="vertical" size="l">
              {hasQueriedOngoing &&
                !labState.labId &&
                hasNoCredits(creditState) && (
                  <LabCreditBanner {...creditState} error={creditStateError} />
                )}
              {!isOngoingLab && (
                <OngoingLabBanner isStandalone={isStandalone} />
              )}
              {isLoadingBlueprint ? (
                <LabMarkdownLoading />
              ) : (
                <>
                  <ChatBotPromoBanner
                    blueprintName={getBlueprintName(blueprint.arn)}
                    isLabUnderMaintenance={isLabUnderMaintenance}
                  />
                  <SpaceBetween size="s">
                    <CustomCallouts
                      slot={SLOTS.labs}
                      filterName={getBlueprintName(blueprint.arn)}
                    />
                  </SpaceBetween>
                  <LabMarkdown
                    blueprint={blueprint}
                    setToc={setToc}
                    blueprintLocale={blueprintLocale || userLocale}
                    lang={langAttr}
                    ongoingLabId={
                      labState.status === labStatuses.ONGOING
                        ? labState.labId
                        : null
                    }
                    isLabAssessmentEnabled={event.hasFeature(
                      FEATURES.labAssessment
                    )}
                  />
                </>
              )}
              <FeedbackSurveyBox blueprint={blueprint} />
              <SkillBuilderBanner />
              <SpaceBetween size="s">
                <CustomCallouts
                  slot={SLOTS.labsBottom}
                  filterName={getBlueprintName(blueprint.arn)}
                />
              </SpaceBetween>
            </SpaceBetween>
            <ChatBot
              blueprintArn={blueprint.arn}
              gandalfToken={gandalfToken}
              isLabUnderMaintenance={isLabUnderMaintenance}
            />
          </Main>
        }
      ></AppLayout>
      <Footer
        isStandalone={isStandalone}
        blueprint={blueprint}
        appLayoutRef={appLayoutRef}
      />
    </>
  );
};

LabPage.propTypes = {
  isStandalone: PropTypes.bool,
  rootPath: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedBlueprintArn: PropTypes.string,
      blueprintLocale: PropTypes.string,
      labId: PropTypes.string,
    }),
  }),
};

export default LabPage;
